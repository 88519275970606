body {
  margin: 0;
  font-family: "Noto", "Lato", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --main-color: #33f1ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ===== SECTION HEADER UNDERLINE ===== */
hr {
  color: #12c2e0;
  border: 2px solid;
  border-radius: 2px;
  width: 4rem;
  margin: 0.35rem 0 1.5rem 0;
}

main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 100vh;
  margin: 0 auto;
  padding: 100px 7vw;
}

h2, h6, h4 {
  text-align: center;
}

footer {
  min-height: 10vh;
  text-align: center;
  padding: 1rem;
  background-color: #212221;
  color: rgb(220, 220, 220);
}

::selection {
  background: var(--main-color);
  color: black;
}

.main-copy {
  margin: 0 2rem 0 2rem;
  padding: 0 0.7rem 1rem 0.7rem;
}

.text-bigger {
  font-size: 120%;
  line-height: 170%;
}

.about {
  margin: 0 3rem;
  padding: 1.5rem 1.5rem 1rem 2rem;
  background: rgba(0, 0, 0, 0.4);
}

/* ==== Eliminates L/R margin on small screens === */
@media (max-width: 768px) {
  .row, .about {
   margin-left: 0 !important;
   margin-right: 0 !important;
  }
}

.container {
  padding: 0;
}

/* === BACKGROUND STYLES === */
.light-bg {
  background-color: #f5f5f5;
}

.dark-bg {
  background-color: #404140;
  color: white;
}

.pic-bg {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("https://images.unsplash.com/photo-1514562460631-0d690060d1f5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2689&q=80");
  background-color: var(--main-color);
  background-blend-mode: multiply;
  color: white;
}

/* === PROFILE PIC === */
.mainimage {
  border-radius: 50%;
  height: 16rem;
}

/* ===== PROJECT THUMBNAILS ===== */
.project-image {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 250px;
}
.p1 {
  background-image: url("/ss-basecamp1.png");
}
.p2 {
  background-image: url("/ss-greenupseattle.png");
}
.p3 {
  background-image: url("/ss-pictionary1.png");
}
.p4 {
  background-image: url("/ss-demojify1.png");
}

/* ======= global styling ======= */

.bold {
  font-weight: 600;
}

.right {
  text-align: right;
}

.top-margin {
  margin-top: 1rem;
}

.width-sm {
  width: 5%;
}

.width-15 {
  width: 15%;
}

/* -- Overrides Bootstrap's Primary outline button color -- */

.btn-outline-primary {
  color: #00a7c4;
  background-color: transparent;
  background-image: none;
  border-color: #00a7c4;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #00a7c4;
  border-color: #00a7c4;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 167, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #00a7c4;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #00a7c4;
  border-color: #00a7c4;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 167, 255, 0.5);
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-90 {
  min-height: 90vh;
}